import { useTheme } from '@emotion/react'
import { Box, Button, CircularProgress, Divider, InputAdornment, TextField, useMediaQuery } from '@mui/material'
import { IconBusinessplan, IconDownload, IconReceipt2, IconSearch } from '@tabler/icons-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Column, useFilters, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import { toast } from 'react-toastify'
import { Card, ComissionStatus, ContentTitle, FilterButton, Input, PerformanceCard, PerformanceCardProps, TableApiV2 } from '~/components'
import { ECommissionStatus, useGetCommissionsCsvLazyQuery, useGetCommissionsWithMetricsQuery } from '~/graphql/types'
import { SearchQueysData, useDebounce, useQueryString, useToggle } from '~/hooks'
import { formatDate, formatMoney, transformMoney, translateComissionsStatus } from '~/utils'
import { FilterComissionsDrawer } from './components'


export const Commissions: React.FC = () => {
  const theme = useTheme()
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))

  const ref = useRef<any>(null)

   const queryKeys: SearchQueysData[] = [
      { key: 'name', defaultValue: '' },
      { key: 'status', defaultValue: [], parseValue: true },
      { key: 'begin', defaultValue: '' },
      { key: 'end', defaultValue: '' },
    ]
    
    const { page, size, setSearchParams, searchQuerys, paginationQuerySize, searchParams } = useQueryString()
    
    const queryValues = searchQuerys(queryKeys)
    
    const [filterName, setFilterName] = useState(queryValues.name)
  
    const debouncedFilterName = useDebounce(filterName, 1000)
  
    const { isTrue: drawerIsVisible, toggle: toggleDrawer, setIsTrue: setDrawerIsVisible } = useToggle(false)

    useEffect(() => {
      setSearchParams(state => {
        if (debouncedFilterName) {
          state.set('name', debouncedFilterName)
          state.set('page', '0')
        } else {
          state.delete('name')
        }
        return state
      })
    }, [debouncedFilterName])

  const [getAllComissionsCsv, { data: allComissionsCsv, loading: allComissionsIsLoading }] = useGetCommissionsCsvLazyQuery({
    onError(error) {
      toast.error(error.message)
    },
    onCompleted() {
      ref?.current?.link?.click()
    }
  })

  const { data, loading: comissionsListIsLoading } = useGetCommissionsWithMetricsQuery({
     fetchPolicy: 'no-cache',
    variables: {
      params: {
        pageSize: size,
        pageNumber: page,
        filter: {
          ...queryValues.name?.length > 0 ? { nameCustomerOrSalesman: queryValues.name } : {},
          ...queryValues?.status?.length > 0 ? { status: queryValues.status } : {},
          ...queryValues?.begin?.length > 0 ? { begin: new Date(queryValues.begin) } : {},
          ...queryValues?.end?.length > 0 ? { end: new Date(`${queryValues.end}Z23:59:59`) } : {}
        },
        sort: {
          field: 'createdAt',
          order: -1
        },
      },
      metricParams: {
        pageSize: size,
        pageNumber: page,
        filter: {
          ...queryValues.name?.length > 0 ? { nameCustomerOrSalesman: queryValues.name } : {},
          ...queryValues?.status?.length > 0 ? { status: queryValues.status } : {},
          ...queryValues?.begin?.length > 0 ? { begin: new Date(queryValues.begin) } : {},
          ...queryValues?.end?.length > 0 ? { end: new Date(`${queryValues.end}Z23:59:59`) } : {}
        },
        sort: {
          field: 'createdAt',
          order: -1
        },
      }
    },
    onError(error) {
      toast.error(error.message)
    },
    onCompleted(result) {
      setSearchParams(state => {
        state.set('items', String(result.getCommissions.meta.documentCounts))
        return state
      })
    },
  })

  // eslint-disable-next-line no-unused-vars
  const onFetchCSV = useCallback(() => {
    getAllComissionsCsv({
      variables: {
        params: {
          pageSize: 999999999,
          filter: {
            ...queryValues?.begin?.length > 0 ? { begin: new Date(queryValues.begin) } : {},
            ...queryValues?.end?.length > 0 ? { end: new Date(`${queryValues.end}Z23:59:59`) } : {}
          },
          sort: {
            field: 'createdAt',
            order: -1
          }
        }
      }
    })
  }, [queryValues])

  const columns = useMemo((): Column[] => {
    return [
      {
        Header: 'Nome',
        accessor: 'indicationRef.salesman.name',
      },
      {
        Header: 'Data de vencimento',
        accessor: 'dueDate',
        Cell: ({ value }) => <>{formatDate(value)}</>
      },
      {
        Header: 'Valor',
        accessor: 'valueCents',
        Cell: ({ value }) => <>{formatMoney(transformMoney(value, 'toReal'))}</>
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => <ComissionStatus status={value} />
      },
    ]
  }, [])

  const dataWithMemo = useMemo(() => data?.getCommissions.data || [], [data])

  const tableMethods = useTable({
    columns,
    data: dataWithMemo,
    manualPagination: true,
    initialState: {
      hiddenColumns: []
    },
    defaultColumn: {
      Filter: <></>,
    },
  },
  useFilters,
  useSortBy,
  usePagination,
  useRowSelect,
    //disable checkboxColumn
    // (hooks) => renderTableCheckbox(hooks)
  )

  const cards: PerformanceCardProps[] = [
    {
      title: 'Receita esperada',
      description: 'Somatório das comissões esperadas para o período',
      value: formatMoney(transformMoney(data?.getCommissionMetrics?.expectedRevenueCents || 0, 'toReal')),
      icon: <IconReceipt2 color='#9E78BC' size={24} />
    },
    {
      title: 'Receita a receber',
      description: 'Somatório das comissões efetivas para o período',
      value: formatMoney(transformMoney(data?.getCommissionMetrics?.receivableRevenueCents || 0, 'toReal')),
      icon: <IconBusinessplan color='#9E78BC' size={24} />
    },
  ]

  return (
    <Box sx={{ minHeight: '100vh', height: '100%' }}>
      <Card>

        <ContentTitle
          title='Comissões'
          style={{ padding: '0px 0px 1.6rem 0px' }}
          description='Consulte as comissões resultantes das indicações de assinatura Desperta'
          breadcrumbLinks={{ currentLink: 'Lista de comissões', links: [{ href: '/app', label: 'Comissão' }] }}
        />
        <Divider />

        <Box sx={{ padding: '1.6rem 0', display: 'flex', justifyContent: 'space-between', flexFlow: isLowerMd ? 'column' : 'row', gap: '1rem' }}>

          <TextField
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
            sx={{ width: '100%', maxWidth: '420px' }}
            InputProps={{
              endAdornment: <InputAdornment position='end'>
                <IconSearch />
              </InputAdornment>,
            }}
            name='searchName'
            placeholder='Busque por palavra-chave'
          />

          <Box sx={{ display: 'flex', gap: '1rem', }}>
            <Button
              onClick={onFetchCSV}
              color='secondary'
              disabled={allComissionsIsLoading}
              startIcon={allComissionsIsLoading ? <CircularProgress size={24} color='inherit' /> : <IconDownload size={24} />}
            >
              {allComissionsIsLoading ? 'exportando' : 'Exportar'}
            </Button>
            <CSVLink
              style={{ visibility: `hidden` }}
              filename='desperta-energia-comissões.csv'
              data={allComissionsCsv ? allComissionsCsv?.getCommissions.data.map(item => ({
                createdAt: formatDate(item.createdAt),
                dueDate: formatDate(item.dueDate),
                paymentDate: item.status === ECommissionStatus.paid ? formatDate(item.updatedAt) : null,
                status: translateComissionsStatus(item.status),
                'nome do vendedor': item.indicationRef.salesman.name,
                'ID do vendedor': item.indicationRef.salesman.id,
                'comissão base': `${item?.indicationRef?.condition?.baseCommissionPercentage}%`,
                'comissão adicional': `${item?.indicationRef?.condition?.additionalComissionPercentage}%`,
                'comissão final': `${item?.indicationRef?.condition?.commissionAmountPercentage}%`,
                'valor da comissão': formatMoney(transformMoney(item.valueCents, 'toReal')),
                estado: item?.indicationRef?.condition?.uf,
                concessionária: item?.indicationRef?.condition?.dealershipName,
              })) : []
              }
              ref={ref}
            >
            </CSVLink>
            <FilterButton onCustomClear={() => setFilterName('')} filtersToClear={queryKeys} toggleDrawer={toggleDrawer} filterCounter={searchParams.size - paginationQuerySize} />
          </Box>

           <FilterComissionsDrawer queryKeys={queryKeys} drawerIsOpen={drawerIsVisible} setDrawerIsOpen={setDrawerIsVisible} toggleDrawer={toggleDrawer} />
        </Box>

        <Divider />

        {/* <Box sx={{ display: 'flex', alignItems: isLowerMd ? 'flex-start' : 'center', gap: '.6rem', justifyContent: 'space-between', padding: '1.6rem 0', flexFlow: isLowerMd ? 'column' : 'row' }}>
          <Box sx={{ display: 'flex', alignItems: isLowerSm ? 'flex-start' : 'center', gap: '.6rem', flexFlow: isLowerSm ? 'column' : 'row' }}>
            <Typography sx={{ fontSize: '1.4rem', fontWeight: 500, color: theme.palette.grey[800] }}>Performance</Typography>
            <Typography sx={{ color: theme.palette.grey[400] }}>Todas as comissões</Typography>
          </Box>
          {filterTable.begin && filterTable.end && (
            <Box sx={{ display: 'flex', alignItems: isLowerSm ? 'flex-start' : 'center', gap: '.6rem', flexFlow: isLowerSm ? 'column' : 'row' }}>
              <Typography sx={{ color: theme.palette.grey[400] }}>Resultados de</Typography>
              <Typography sx={{ color: theme.palette.grey[800], fontWeight: 500 }}>{formatDate(`${new Date(filterTable.begin)}`)} a {formatDate(`${new Date(filterTable.end)}`)}</Typography>
              {!isLowerSm && <IconCalendar color={theme.palette.primary.main} />}
            </Box>
          )}

        </Box> */}

        <Box sx={{ display: 'flex', gap: '1rem', flexFlow: isLowerMd ? 'column' : 'row' }}>
          {cards.map((card) => {
            return <PerformanceCard key={card.title} {...card} />
          })}
        </Box>

        <Divider sx={{ marginTop: '1.6rem' }} />

        <TableApiV2
          tableLayout='auto'
          isLoading={comissionsListIsLoading}
          tableMethods={tableMethods}
          columns={columns}
        />

      </Card>
    </Box>
  )
}
